body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.body {
  margin: 50px 0 30px 0;
  padding-left: 100px;
  padding-right: 100px;
  color: #6c6c6c;
}

.form-control::placeholder {
  color: #6c6c6c;
  /* font-size: 0.6rem; */
}

.cash-deposit-container {
  width: auto;
}

.header {
  height: 100px;
  padding: 10px;
  /* background-color: #2c3c53; */
  font-weight: bold;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: between;
}

.header-title {
  font-size: 1.3rem;
  font-weight: bold;
  text-align: center;
  flex-grow: 1;
}

.header-icon {
  font-size: 20px;
  margin-left: 10px;
  position: absolute;
  right: 5px;
  top: auto;
  width: 60px;
  height: 60px;
}

.prefilled-input {
  display: flex;
  align-items: center;
  width: 100%;
  height: 40px;
  margin: 0 0 15px 0;
  background-color: #efefee;
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  border-radius: 10px;
}

.prefilled-input.shake {
  animation: shake 0.5s;
}

.title {
  font-size: 1rem;
  font-weight: bold;
  margin: 0 0 0px 0;
}

@keyframes shake {
  0% {
    transform: translateX(0);
  }
  10%,
  30%,
  50%,
  70%,
  90% {
    transform: translateX(-5px);
  }
  20%,
  40%,
  60%,
  80% {
    transform: translateX(5px);
  }
  100% {
    transform: translateX(0);
  }
}

ol {
  list-style-type: decimal;
  counter-reset: item;
  padding-left: 1rem !important;
}

li {
  counter-increment: item;
  margin-bottom: 10px;
}

.form-control {
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  border-radius: 10px;
  height: 40px;
}

.btn.btn-submit {
  background-color: #7dedf5;
  border-radius: 25px;
}
.btn.btn-submit:hover {
  background-color: #7dedf5;
  opacity: 0.8;
  transform: scale(1.01);
  border-radius: 25px;
}

.bi-clipboard {
  margin-left: auto;
}

.bi-clipboard:active {
  transform: scale(1.3);
}

.form-control {
  margin: 0 0 15px 0;
}

.depositWrapper {
  width: 100%;
  border: 1px solid;
  padding: 20px;
  background-color: #2c3c53;
  color: #fff;
  border-radius: 5%;
}

.depositItem {
  margin: 0 0 10px 0;
}

.content {
  display: flex;
  align-items: center;
}

.content svg {
  margin: 0 0 0 5px;
}

.instruction {
  font-size: 0.75rem;
}

.iconCircle {
  background-color: #fff;
  height: 120px;
  width: 120px;
  border-radius: 50%;
  position: relative;
  top: -80px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: -60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: #2c3c53 solid 3px;
}

.horizontalLn {
  border: none;
  border-top: 1px dotted #fff;
  margin: 20px 0 20px 0;
}

.fv-plugins-message-container {
  color: red;
}

form input {
  /* margin: 0 0 15px 0; */
  height: 40px;
}

.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #009a9a;
  margin: 10px 0 10px 0;
}

.footerTitle {
  font-weight: bold;
  font-size: 1.5rem;
}

.footer span {
  color: #6c6c6c;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 0.8rem;
}

.response-instruction-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.response-instruction {
  font-size: 1.3rem;
  font-weight: bold;
}

.response-subIntruction {
  font-size: 0.8rem;
}

label {
  font-size: 0.75rem;
}

.justpay-logo {
  width: 300px;
}

.footer div .justpay-logo {
  width: 300px;
}

@media (max-width: 768px) {
  .body {
    margin: 15px 0 15px 0;
    padding-left: 60px;
    padding-right: 60px;
  }

  .header {
    height: 45px;
  }

  .justpay-logo {
    width: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .footer div .justpay-logo {
    width: 200px;
  }

  .footer {
    font-size: 0.75rem;
    padding-left: 60px;
    padding-right: 60px;
  }

  .header-icon {
    width: 30px;
    height: 30px;
  }

  input::placeholder {
    white-space: normal;
    font-size: 0.6rem;
  }
}
